import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { ImagePage } from "./EditPdfComponent/ImagePage";
import { Attachments } from "./EditPdfComponent/Attachments";
import { useAttachments } from "../hooks/useAttachments";
import { ggID } from "../utils/helpers";
import WebFont from "webfontloader";
import Modal from "react-bootstrap/Modal";
import { API } from "../API";
import Loading from "react-fullscreen-loading";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Pen, Plus, Trash } from "react-bootstrap-icons";
import { Spinner } from "react-bootstrap";
import { downloadImage, editImage } from "../utils/image";

export const EditImageProject = ({
  fileConatiner,
  pdfDocument,
  varible,
  csvVariables,
  projectId,
  pdfFile,
  failedStatus,
  customMsg,
  delaySecond,
}) => {
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [pageIndex, setPageIndexses] = useState(-1);
  const [dimensions, setDimensions] = useState();
  const [file, setFile] = useState();
  const [csvVarible, setCsvVarible] = useState([]);
  const [demoVarible, setDemovarible] = useState({});
  const [rgbColor, SetRgbColor] = useState({
    r: 212,
    g: 17,
    b: 17,
  });

  const containerRef = useRef(null);

  let [sendedFiles, setSendedFiles] = useState(0);
  const [project, setProject] = useState({});
  const [fontSize, setFontSize] = useState(14);
  const [fontFamily, setFontFamily] = useState("Kumar One");
  const [qrModalStatus, setQrModalStatus] = useState(false);
  const [qrData, setQrData] = useState("");
  const [mobileModalStatus, setMobileModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileNo, setMobileNo] = useState("91");
  const [sendFileModalStatus, setSendFileModalStatus] = useState(false);
  const [creditData, setCreditData] = useState({});
  const [isScan, setIsScan] = useState(false);
  const [loadingText, setLoginText] = useState("");
  const [countUser, setCountUser] = useState(0);
  const [imageLoadingStatus, setImageLodingStatus] = useState(false);
  const [editText, setEditText] = useState({});
  const [editableTextStatus, setEditableTextStatus] = useState(false);
  const [cancelButtonIsDisable, setcancelButtonDisable] = useState(false);

  let timer;
  const initializePageAndAttachments = useCallback((pdfDocument) => {
    const { name, file } = pdfDocument;
    setName(name);
    setFile(file);
    setPageIndexses(0);
    // setPages(1);
    const numberOfPages = 1;
    resetAttachments(numberOfPages);
  }, []);
  useEffect(() => {
    setDemovarible(varible);
    initializePageAndAttachments(pdfDocument);
    // eslint-disable-next-line no-use-before-define
  }, [initializePageAndAttachments, pdfDocument, varible]);
  const getAllVarible = useCallback(async () => {
    let value = [];
    const _value = demoVarible;
    Object.entries(_value).map((s, index) => {
      // console.log();
      if (s[0] !== "number") {
        if (s[0] !== "_id") {
          value.push({ [s[0]]: s[1], added: false });
        }
      }
      return s;
    });
    setCsvVarible(value);
  }, [demoVarible]);
  useEffect(() => {
    getAllVarible();
  }, [demoVarible, getAllVarible]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Kumar One",
          "Aakar",
          "BHUJ-UNICODE",
          "Ekatra",
          "Surat-Unicode",
          "Shruti",
          "Noto Serif Gujarati",
          "Noto Sans Gujarati",
          "Rasa",
          "Padmaa",
          "Rekha",
          "Ekatra-B",
          "HindVadodara-Bold",
          "Lohit_Gujarati",
          "Mogra",
          "MuktaVaani-Bold",
          "MuktaVaani-ExtraBold",
          "RAJKOT-UNICODE",
        ],
      },
      fontactive: () => {
        setTimeout(() => {
          // setLoaded(true);
        }, 50);
      },
    });
  }, []);
  const {
    add: addAttachment,
    allPageAttachments,
    pageAttachments,
    reset: resetAttachments,
    update,
    // updateAttachment:
    remove: removeAttachment,
    setPageIndex,
  } = useAttachments();

  const setDimensionsHandler = useCallback(setDimensions, [setDimensions]);

  const addTestText = (varible, text, index) => {
    const newTextAttachment = {
      id: ggID(),
      type: "text",
      x: 0,
      y: 0,
      height: 25,
      lineHeight: 1.4,
      size: fontSize,
      fontFamily: fontFamily,
      text: varible,
      variableName: Object.keys(varible)[0],
      color: rgbColor,
    };
    addAttachment(newTextAttachment);
    csvVarible[index]["added"] = true;
    setCsvVarible(csvVarible);
    setFontSize(14);
  };

  const editVaribleText = (s, pageIndex, textIndex) => {
    setEditText({ data: s, pageIndex: pageIndex, textIndex: textIndex });
    setEditableTextStatus(true);
  };

  const handleColorChange = (e) => {
    const rgbaColor = hexToRgba(e.target.value);
    let _data = editText.data;
    _data.color = rgbaColor;
    setEditText((prevState) => ({
      ...prevState,
      data: _data,
    }));
  };
  const hexToRgba = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const fullHexRegex =
      /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i;
    const result = shorthandRegex.exec(hex) || fullHexRegex.exec(hex);

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    const a = result[4] ? parseInt(result[4], 16) / 255 : 1;

    return { r, g, b, a };
  };

  const saveImage = async (attachments) => {
    console.log("attac", attachments);
    if (!file) return;
    // setIsSaving(true);
    try {
      downloadImage(pdfDocument.url, attachments[0], varible);
    } catch (e) {
      // console.log("error", e);
    } finally {
      // setIsSaving(false);
    }
  };

  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };
  //   window.addEventListener("unload", async (event) => {
  //     localStorage.setItem("projectId", JSON.stringify(projectId));
  //   });
  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, [loading, projectId]);
  const testPdf = async (attachments) => {
    setMobileModalStatus(false);
    if (isScan) {
      const { data } = await API.get(
        `/project/check-test-project?projectId=${projectId}`
      );
      // console.log("=data", data);
      if (data.success) {
        if (mobileNo !== "") {
          if (!file) return;
          try {
            let string_result = customMsg;
            for (const key in varible) {
              if (varible.hasOwnProperty(key)) {
                const replacement =
                  typeof varible[key] === "string"
                    ? varible[key].trim()
                    : varible[key].toString();

                const regex = new RegExp(`\\$\\{${key}\\}`, "g");
                string_result = string_result.replace(regex, replacement);
              }
            }
            setLoading(true);
            const buffImage = await editImage(
              pdfDocument.url,
              attachments[0],
              varible
            );
            const data = new FormData();
            data.append(
              "file",
              new Blob([buffImage], { type: "application/png" })
            );
            data.append("mobileNo", mobileNo);
            data.append("message", string_result);
            data.append("projectId", projectId);
            data.append("pdfName", varible.name);
            const result = await API.post(
              `/whatsapp/sendtestmessage?projectId=${projectId}`,
              data
            );
            if (result.data.success) {
              setLoading(false);
              // setlogOutStatus(true);
              toast.success(result.data.message);
            } else {
              setLoading(false);
              toast.error(result.data.message);
            }
          } catch (e) {
            // console.log("error", e);
          }
        } else {
          toast.error("please enter mobile no");
        }
      } else {
        toast.error("You are already used");
      }
    } else {
      toast.error("Please scan QR");
    }
  };
  function delay(t) {
    return new Promise((resolve) => setTimeout(resolve, t));
  }
  async function sendFile() {
    //send pdf
    // for (const el of csvVariables) {
    //   setImageLodingStatus(true);
    //   setLoginText(`${el.name} sending pdf `);
    //   // console.log("=countUser", countUser);
    //   setCountUser(countUser + 1);
    //   const string = customMsg.split(" ");
    //   let string_result = "";

    //   await string.forEach((element) => {
    //     if (element.startsWith("$")) {
    //       const s1 = element.replace(/[$,{,}]/g, "");
    //       string_result += el[`${s1}`] + " ";
    //     } else {
    //       string_result += element + " ";
    //     }
    //   });

    //   const sendPdf = await editImage(
    //     pdfDocument.url,
    //     allPageAttachments[0],
    //     el
    //   );
    const formData = new FormData();
    // formData.append("file", new Blob([sendPdf], { type: "application/png" }));
    formData.append("projectId", projectId);
    formData.append("csv", JSON.stringify(csvVariables));
    formData.append("attachment", JSON.stringify(allPageAttachments));
    // formData.append("mobileNo", el.number);
    // formData.append("pdfName", el.name);
    formData.append("message", customMsg);
    // formData.append("user", JSON.stringify(el));
    const { data } = await API.post(
      `/whatsapp/sendfiles?projectId=${projectId}`,
      formData
    );
    // if (!data.success) {
    //   return toast.error(data.message);
    // } else {
    //   sendedFiles += 1;
    //   setSendedFiles(sendedFiles);
    //   await delay(delaySecond * 1000);
    // }
    // }
    setImageLodingStatus(false);
    // const { data } = await API.post(`/project/sended-whatsapp-file`, {
    //   projectId: projectId,
    //   user: csvVariables[0],
    // });
    // await API.get(`/whatsapp/logout?projectId=${projectId}`);
    if (data.success) {
      toast.success(data.message);
      navigate("/functions/project", {
        state: { id: project.type },
      });
    } else toast.error(data.message);
  }

  const handleMobileClose = () => setMobileModalStatus(false);

  let timer2;
  const getQr = async () => {
    if (!qrData) {
      setLoading(true);
      timer2 = setInterval(async () => {
        const sesisonData = await API.get(
          `/whatsapp/issessionsaved/?projectId=${projectId}`
        );
        if (sesisonData.data.success === false) {
          const { data } = await API.get(
            `/whatsapp/getqr/?projectId=${projectId}`
          );
          setLoading(false);
          if (data.success) {
            setQrModalStatus(true);
            setQrData(data.data);
            getQrData();
          } else {
            toast.error(data.message);
          }
        }
      }, 10000);
    }
    if (qrData) {
      clearInterval(timer2);
    }
  };
  const getQrData = () => {
    return clearInterval(timer2);
  };
  useEffect(() => {
    async function sessionExist() {
      const { data } = await API.get(
        `/whatsapp/issessionsaved?projectId=${projectId}`
      );
      if (data.success) setIsScan(true);
    }
    sessionExist();
  }, [projectId]);
  useLayoutEffect(() => setPageIndex(pageIndex), [pageIndex, setPageIndex]);

  const getScanQrCodeStatus = async () => {
    if (qrModalStatus) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(async () => {
        const { data } = await API.get(
          `/whatsapp/issessionsaved?projectId=${projectId}`
        );
        if (data.success) {
          setIsScan(true);
          setQrModalStatus(false);
        }
        // setSession(data);
      }, 10000);
    }
    if (!qrModalStatus) {
      clearInterval(timer);
    }
  };
  useEffect(() => {
    getScanQrCodeStatus();
    return () => clearInterval(timer);
  }, [qrModalStatus, timer]);

  async function onCancel() {
    setcancelButtonDisable(true);
    const { data } = await API.patch(
      `/project/project-status-change?projectId=${projectId}`
    );
    await API.get(`/whatsapp/logout?projectId=${projectId}`);
    if (data.success) {
      navigate("/functions/project", {
        state: { id: projectId },
      });
    }
  }

  useEffect(() => {
    const getProject = async () => {
      const res = await API.get(`/project/get/${projectId}`);
      setProject(res.data.data);
    };
    getProject();
  }, [projectId]);

  return (
    <>
      <div>
        <div className="row mx-0">
          <div
            className="col-md-3"
            style={{ borderRight: "1px solid #ddd", position: "relative" }}
            ref={containerRef}
          >
            <div className="d-flex justify-content-end">
              {!failedStatus && (
                <div className="mt-3">
                  <div className="col-md-12 ">
                    <button
                      onClick={() => saveImage(allPageAttachments)}
                      className="btn-gradient-primary p-2 border rounded"
                    >
                      Download
                    </button>
                  </div>
                </div>
              )}
              <div>
                {!isScan ? (
                  <div className="mt-3 ms-2">
                    <div
                      className="col-md-12 text-end"
                      style={{ fontSize: 16 }}
                    >
                      <button
                        onClick={() => getQr()}
                        className="btn-gradient-primary p-2 border rounded"
                      >
                        Scan Qr
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 ms-2">
                    <div
                      className="col-md-12 text-end"
                      style={{ fontSize: 16 }}
                    >
                      <button
                        onClick={async () => {
                          const { data } = await API.get(
                            `/whatsapp/logout?projectId=${projectId}`
                          );
                          if (data?.success) {
                            toast.success(data.message);
                            setIsScan(false);
                            setQrData("");
                          }
                        }}
                        className="btn-gradient-primary p-2 border rounded"
                      >
                        Log Out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {!failedStatus && (
                <>
                  <div className="mt-3">
                    <div className="col-md-12">
                      <button
                        onClick={() => setMobileModalStatus(true)}
                        className="btn-gradient-primary p-2 border rounded"
                      >
                        Test
                      </button>
                    </div>
                  </div>
                </>
              )}
              <div className="mt-3">
                <div className="col-md-12 ms-2">
                  <button
                    onClick={async () => {
                      if (isScan) {
                        const { data } = await API.post("/credit/checkcredit", {
                          count: csvVariables.length,
                        });
                        if (data.success) {
                          setCreditData(data);
                          setSendFileModalStatus(true);
                        } else {
                          toast.error(data.message);
                        }
                      } else {
                        toast.error("Please scan QR");
                      }
                    }}
                    className="btn-gradient-primary p-2 border rounded"
                  >
                    Send File
                  </button>
                </div>
              </div>
            </div>
            <div
              className="text-secondary mt-3 fw-bold"
              style={{ fontSize: 20 }}
            >
              {csvVarible.length > 0 ? "Add Values" : "Action"}
            </div>
            <div>
              {csvVarible.length > 0 && (
                <>
                  <div>
                    {csvVarible.map((s, index) => {
                      return (
                        <div key={index}>
                          <div className="mb-2 rounded p-2 mt-3 border">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="w-60">{Object.values(s)}</div>
                              <div className="d-flex">
                                {!Object.values(s)[1] && (
                                  <div
                                    className="rounded p-1 ms-2 d-flex align-items-center"
                                    style={{
                                      border: "1px solid #006aa8",
                                      cursor: "pointer",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    onClick={() => {
                                      addTestText(s, Object.values(s), index);
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Plus size={20} color="#006aa8" />
                                    </div>
                                  </div>
                                )}

                                {Object.values(s)[1] && (
                                  <div
                                    className="rounded p-2 ms-2"
                                    style={{
                                      cursor: "pointer",
                                      border: "1px solid #ffbf23",
                                      width: "30px",
                                      height: "30px",
                                      backgroundColor: "#ffbf23",
                                    }}
                                    onClick={() => {
                                      allPageAttachments.map(
                                        (page, pageIndex) => {
                                          if (page.length > 0) {
                                            page.map(
                                              (textVarible, indexText) => {
                                                const textMatch = Object.keys(
                                                  textVarible.text
                                                )[0];
                                                if (
                                                  textMatch ===
                                                  Object.keys(s)[0]
                                                ) {
                                                  editVaribleText(
                                                    textVarible,
                                                    pageIndex,
                                                    indexText
                                                  );
                                                }
                                                return textVarible;
                                              }
                                            );
                                          }
                                          return page;
                                        }
                                      );
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Pen size={12} color="#006aa8" />
                                    </div>
                                  </div>
                                )}
                                {Object.values(s)[1] && (
                                  <div
                                    className="rounded p-2 ms-2"
                                    style={{
                                      cursor: "pointer",
                                      border: "1px solid #ffbf23",
                                      width: "30px",
                                      height: "30px",
                                      backgroundColor: "#ffbf23",
                                    }}
                                    onClick={() => {
                                      allPageAttachments.map(
                                        (page, pageIndex) => {
                                          if (page.length > 0) {
                                            page.map(
                                              (textVarible, indexText) => {
                                                const textMatch = Object.keys(
                                                  textVarible.text
                                                )[0];
                                                if (
                                                  textMatch ===
                                                  Object.keys(s)[0]
                                                ) {
                                                  setPageIndex(pageIndex);

                                                  removeAttachment(indexText);
                                                  csvVarible[index][
                                                    "added"
                                                  ] = false;
                                                }
                                                return textVarible;
                                              }
                                            );
                                          }
                                          return page;
                                        }
                                      );
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Trash size={14} color="#006aa8" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {csvVarible.length === 0 && (
                <>
                  {!failedStatus && (
                    <>
                      <div className="mt-3">
                        <div className="col-md-12 ">
                          <button
                            onClick={() => saveImage(allPageAttachments)}
                            className="btn-gradient-primary p-2 border rounded"
                          >
                            Download
                          </button>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="col-md-12">
                          <button
                            onClick={() => setMobileModalStatus(true)}
                            className="btn-gradient-primary p-2 border rounded"
                          >
                            Test
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="mt-3">
                    <div className="col-md-12 ">
                      <button
                        onClick={async () => {
                          if (isScan) {
                            const { data } = await API.post(
                              "/credit/checkcredit",
                              {
                                count: csvVariables.length,
                              }
                            );
                            if (data.success) {
                              setCreditData(data);
                              setSendFileModalStatus(true);
                            } else {
                              toast.error(data.message);
                            }
                          } else {
                            toast.error("Please scan qr");
                          }
                        }}
                        className="btn-gradient-primary p-2 border rounded"
                      >
                        Send File
                      </button>
                    </div>
                  </div>
                </>
              )}

              {editableTextStatus && (
                <Modal
                  show={editableTextStatus}
                  container={containerRef.current}
                  onHide={() => setEditableTextStatus(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                  backdrop={false}
                  size="sm"
                >
                  <Modal.Header className="px-4" closeButton>
                    <Modal.Title className="ms-auto page-title fw-bold">
                      Edit text
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div className="p-3 bg-light">
                        <div className="">
                          <div className="text-center page-title fw-bold">
                            Font Formating
                          </div>
                          <div className="row mt-2 ">
                            <div className="col-12 ">
                              <div className="form-group">
                                <label className="lable-fw-size">
                                  FontFamily
                                </label>
                                <select
                                  name="text"
                                  className={`zip-select form-control mt-1 form-fs form-label-border p-2 ${
                                    editText.data.fontFamily === "BHUJ-UNICODE"
                                      ? "font-face-bu"
                                      : editText.data.fontFamily ===
                                        "Surat-Unicode"
                                      ? "font-face-su "
                                      : editText.data.fontFamily === "Shruti"
                                      ? "font-face-shruti"
                                      : editText.data.fontFamily === "Padmaa"
                                      ? "font-face-padmaa"
                                      : editText.data.fontFamily === "Rekha"
                                      ? "font-face-rekha"
                                      : editText.data.fontFamily === "Ekatra-B"
                                      ? "font-face-ekatra-B"
                                      : editText.data.fontFamily ===
                                        "HindVadodara-Bold"
                                      ? "font-face-hindVadodara-Bold"
                                      : editText.data.fontFamily ===
                                        "Lohit_Gujarati"
                                      ? "lohit_Gujarati"
                                      : editText.data.fontFamily === "Mogra"
                                      ? "mogra"
                                      : editText.data.fontFamily ===
                                        "MuktaVaani-Bold"
                                      ? "font-face-muktaVaani-Bold"
                                      : editText.data.fontFamily ===
                                        "MuktaVaani-ExtraBold"
                                      ? "font-face-muktaVaani-ExtraBold"
                                      : editText.data.fontFamily ===
                                        "RAJKOT-UNICODE"
                                      ? "font-face-RAJKOT-UNICODE"
                                      : ""
                                  }`}
                                  style={{
                                    fontFamily: editText.data.fontFamily,
                                  }}
                                  value={editText.data.fontFamily}
                                  onChange={(e) => {
                                    let _data = editText.data;
                                    _data.fontFamily = e.target.value;
                                    setEditText((prevState) => ({
                                      ...prevState,
                                      data: _data,
                                    }));
                                  }}
                                >
                                  {[
                                    "Kumar One",
                                    "Aakar",
                                    "BHUJ-UNICODE",
                                    "Ekatra",
                                    "Surat-Unicode",
                                    "Shruti",
                                    "Noto Serif Gujarati",
                                    "Noto Sans Gujarati",
                                    "Rasa",
                                    "Padmaa",
                                    "Rekha",
                                    "Ekatra-B",
                                    "HindVadodara-Bold",
                                    "Lohit_Gujarati",
                                    "Mogra",
                                    "MuktaVaani-Bold",
                                    "MuktaVaani-ExtraBold",
                                    "RAJKOT-UNICODE",
                                  ].map((s, i) => (
                                    <option
                                      value={s}
                                      className={
                                        s === "BHUJ-UNICODE"
                                          ? "font-face-bu"
                                          : s === "Surat-Unicode"
                                          ? "font-face-su "
                                          : s === "Shruti"
                                          ? "font-face-shruti"
                                          : s === "Padmaa"
                                          ? "font-face-padmaa"
                                          : s === "Rekha"
                                          ? "font-face-rekha"
                                          : s === "Ekatra-B"
                                          ? "font-face-ekatra-B"
                                          : s === "HindVadodara-Bold"
                                          ? "font-face-hindVadodara-Bold"
                                          : s === "Lohit_Gujarati"
                                          ? "font-face-lohit_Gujarati"
                                          : s === "Mogra"
                                          ? "font-face-mogra"
                                          : s === "MuktaVaani-Bold"
                                          ? "font-face-muktaVaani-Bold"
                                          : s === "MuktaVaani-ExtraBold"
                                          ? "font-face-muktaVaani-ExtraBold"
                                          : s === "RAJKOT-UNICODE"
                                          ? "font-face-RAJKOT-UNICODE"
                                          : ""
                                      }
                                      style={{ fontFamily: `${s}` }}
                                    >
                                      {s}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group mt-2">
                                <label className="lable-fw-size">Color</label>
                                <div className="mt-1">
                                  <input
                                    type="color"
                                    id="color"
                                    name="color"
                                    onChange={handleColorChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mt-2">
                              <div className="form-group">
                                <label className="lable-fw-size">
                                  Font Size
                                </label>
                                <select
                                  name="text"
                                  className={`form-control mt-1 form-fs form-label-border p-2 `}
                                  value={editText.data.size}
                                  onChange={(e) => {
                                    let _data = editText.data;
                                    _data.size = e.target.value;
                                    setEditText((prevState) => ({
                                      ...prevState,
                                      data: _data,
                                    }));
                                  }}
                                >
                                  {[
                                    2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24,
                                    26, 28, 30,
                                  ].map((s, i) => (
                                    <option value={s}>{s}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container mt-3">
                        <div className="text-center">
                          <div
                            onClick={() => {
                              setPageIndex(editText.pageIndex);
                              update(editText.textIndex, editText.data);
                              setEditableTextStatus(false);
                            }}
                            className="btn-gradient-primary p-2 border rounded  p-2"
                          >
                            Edit Text
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div>
          <div className="col-md-9 col-12">
            {" "}
            <div className="container p-0 px-md-2">
              <div className="row flex-md-row flex-column align-items-center">
                <div className="col-12 col-md-8">
                  <div className="edit-pdf-ui">
                    <div
                      style={{ position: "relative" }}
                      className="overflow-auto"
                    >
                      <ImagePage
                        imageURL={pdfDocument.url}
                        dimensions={dimensions}
                        updateDimensions={setDimensionsHandler}
                      />
                      {dimensions && (
                        <Attachments
                          pdfName={name}
                          updateAttachment={update}
                          pageDimensions={dimensions}
                          attachments={pageAttachments}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {qrModalStatus && (
            <Modal
              show={qrModalStatus}
              backdrop="static"
              centered
              className="qrmodal"
            >
              <Modal.Body>
                <div className="m-3">
                  <div
                    style={{
                      width: "264px",
                      height: "264px",
                      background: `url(${qrData})`,
                    }}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )}
          {mobileModalStatus && (
            <Modal show={mobileModalStatus} onHide={handleMobileClose} centered>
              <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto page-title">
                  Add Mobile No
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="m-3">
                  <div className="form-group md-4">
                    <label className="lable-fw-size">
                      Mobile No with country code{" "}
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="tel"
                      id="mobile_no"
                      className="form-control form-fs form-label-border mt-1 "
                      name="mobile_no"
                      maxLength="12"
                      defaultValue={mobileNo}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setMobileNo(numericValue);
                      }}
                      onKeyDown={(e) => {
                        if (
                          (["e", "E", "+", "-", "."].includes(e.key) ||
                            /[a-zA-Z]/.test(e.key)) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      pattern="[0-9]*"
                    ></input>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn-light fw-bold p-2 border rounded"
                  onClick={handleMobileClose}
                  style={{ width: "15%" }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn-gradient-primary fw-bold p-2 border rounded"
                  style={{ width: "15%" }}
                  onClick={() => testPdf(allPageAttachments)}
                >
                  Yes
                </button>
              </Modal.Footer>
            </Modal>
          )}

          {sendFileModalStatus && (
            <Modal
              show={sendFileModalStatus}
              onHide={() => setSendFileModalStatus(false)}
              centered
            >
              <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto page-title">
                  Confirm Send File
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="m-3">
                  <div className="form-group md-4">
                    <div className="lable-fw-size">{`Your Total pdf is : ${csvVariables.length}`}</div>
                    <div className="lable-fw-size">{`Your cut balance is : ${Number(
                      creditData?.data?.totalCharge
                    ).toFixed(2)}`}</div>
                    <div className="lable-fw-size">{`Your balance will be reduced to : ${Number(
                      creditData.data.remainingCredit
                    ).toFixed(2)}`}</div>
                    <div className="lable-fw-size">{`If you want to continue, click on Continue.`}</div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn-light fw-bold p-2 btn-gradient-primary p-2 border rounded"
                  onClick={() => setSendFileModalStatus(false)}
                  style={{ width: "15%" }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-gradient-primary fw-bold p-2 border rounded "
                  style={{ width: "18%" }}
                  onClick={async () => {
                    const { data } = await API.post(`auth/update-holdBalance`, {
                      count: csvVariables.length,
                      projectId: projectId,
                    });
                    if (data.success) {
                      setSendFileModalStatus(false);
                      sendFile();
                    } else {
                      toast.error("Network Error");
                    }
                  }}
                >
                  Continue
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
        {imageLoadingStatus && (
          <>
            <Modal
              show={imageLoadingStatus}
              backdrop="static"
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              dialogClassName="continuebooking-modal m-3 m-sm-auto"
              centered
            >
              <Modal.Body>
                <div className="load-data-modal-content d-flex flex-column align-items-center p-4">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: "#9a55ff" }}
                  />
                  <span className="mt-2">
                    {sendedFiles} / {csvVariables.length}
                  </span>
                  <span className="mt-2 mb-2 title-font white-8 font-20 text-uppercase">
                    PLEASE WAIT,
                  </span>
                  <span className="title-font white-8 font-20 text-uppercase">
                    {loadingText} ............
                  </span>
                  <div
                    onClick={() => onCancel()}
                    className="btn-gradient-primary mt-2 p-2 border rounded "
                    disabled={cancelButtonIsDisable}
                  >
                    Cancel
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
      {loading && (
        <div>
          <Loading loading={loading} loaderColor="#24292f" />
        </div>
      )}
      {/* <Toaster /> */}
    </>
  );
};
