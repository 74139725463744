import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Page } from "./EditPdfComponent/Page";
import { Attachments } from "./EditPdfComponent/Attachments";
import { useAttachments } from "../hooks/useAttachments";
import { ggID } from "../utils/helpers";
import PreviewText from "./EditPdfComponent/PreviewText";
import { save } from "../utils/pdf";
import WebFont from "webfontloader";
import Modal from "react-bootstrap/Modal";
import { API } from "../API";
// import QRCode from "react-qr-code";
import Loading from "react-fullscreen-loading";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, ArrowRight, Pen, Plus, Trash } from "react-bootstrap-icons";
import { Spinner } from "react-bootstrap";

export const EditPdfProject = ({
  fileConatiner,
  pdfDocument,
  varible,
  csvVariables,
  projectId,
  pdfFile,
  failedStatus,
  customMsg,
  delaySecond,
}) => {
  const [project, setProject] = useState({});
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [pageIndex, setPageIndexses] = useState(-1);
  const [dimensions, setDimensions] = useState();
  const [file, setFile] = useState();
  const [pages, setPages] = useState([]);
  const [isMultiPage, setIsMultiPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  // const [isSaving, setIsSaving] = useState(false);
  const [editTextStatus, setEditTextStatus] = useState(false);
  const [csvVarible, setCsvVarible] = useState([]);
  const [demoVarible, setDemovarible] = useState({});
  const [color, SetColor] = useState({});
  const [rgbColor, SetRgbColor] = useState({
    r: 212,
    g: 17,
    b: 17,
  });

  const containerRef = useRef(null);

  let [sendedFiles, setSendedFiles] = useState(0);
  const [colorCode, setColorCode] = useState("#AD3D2D");
  const [fontSize, setFontSize] = useState(14);
  const [fontFamily, setFontFamily] = useState("Kumar One");
  const [qrModalStatus, setQrModalStatus] = useState(false);
  const [qrData, setQrData] = useState("");
  const [mobileModalStatus, setMobileModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileNo, setMobileNo] = useState("91");
  // const [logoutStatus, setlogOutStatus] = useState(false);
  const [sendFileModalStatus, setSendFileModalStatus] = useState(false);
  const [creditData, setCreditData] = useState({});
  const [isScan, setIsScan] = useState(false);
  // const [bufferData, setBufferData] = useState([]);
  // const [session, setSession] = useState({});
  const [loadingText, setLoginText] = useState("");
  const [countUser, setCountUser] = useState(0);
  const [pdfLoadingStatus, setPdfLodingStatus] = useState(false);
  const [editText, setEditText] = useState({});
  const [editableTextStatus, setEditableTextStatus] = useState(false);
  const [cancelButtonIsDisable, setcancelButtonDisable] = useState(false);
  const currentPage = pages[pageIndex];

  let timer;
  const initializePageAndAttachments = useCallback((pdfDocument) => {
    const { name, file, pages } = pdfDocument;
    const multi = pages.length > 1;
    setName(name);
    setFile(file);
    setPages(pages);
    setPageIndexses(0);
    setIsMultiPage(multi);
    setIsFirstPage(true);
    setIsLastPage(pages.length === 1);
    const numberOfPages = pdfDocument.pages.length;
    resetAttachments(numberOfPages);
  }, []);
  useEffect(() => {
    setDemovarible(varible);

    initializePageAndAttachments(pdfDocument);
    // eslint-disable-next-line no-use-before-define
  }, [initializePageAndAttachments, pdfDocument, varible]);
  const getAllVarible = useCallback(async () => {
    let value = [];
    const _value = demoVarible;
    Object.entries(_value).map((s, index) => {
      // console.log();
      if (s[0] !== "number") {
        if (s[0] !== "_id") {
          value.push({ [s[0]]: s[1], added: false });
        }
      }
      return s;
    });
    // console.log("=valuevaluevaluevaluevaluevaluevalue", value);
    setCsvVarible(value);
  }, [demoVarible]);
  useEffect(() => {
    getAllVarible();
  }, [demoVarible, getAllVarible]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Kumar One",
          "Aakar",
          "BHUJ-UNICODE",
          "Ekatra",
          "Surat-Unicode",
          "Shruti",
          "Noto Serif Gujarati",
          "Noto Sans Gujarati",
          "Rasa",
          "Padmaa",
          "Rekha",
          "Ekatra-B",
          "HindVadodara-Bold",
          "Lohit_Gujarati",
          "Mogra",
          "MuktaVaani-Bold",
          "MuktaVaani-ExtraBold",
          "RAJKOT-UNICODE",
        ],
      },
      fontactive: () => {
        setTimeout(() => {
          // setLoaded(true);
        }, 50);
      },
    });
  }, []);
  const {
    add: addAttachment,
    allPageAttachments,
    pageAttachments,
    reset: resetAttachments,
    update,
    // updateAttachment:
    remove: removeAttachment,
    setPageIndex,
  } = useAttachments();

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const nextPage = () => {
    const newPageIndex = pageIndex + 1;
    setPageIndexses(pageIndex + 1);
    setIsFirstPage(newPageIndex === 0);
    setIsLastPage(newPageIndex === pages.length - 1);
  };
  const previousPage = () => {
    const newPageIndex = pageIndex - 1;
    setPageIndexses(newPageIndex);
    setIsFirstPage(newPageIndex === 0);
    setIsLastPage(newPageIndex === pages.length - 1);
  };
  const setDimensionsHandler = useCallback(setDimensions, [setDimensions]);

  // const addText = () => {
  //   setEditTextStatus(true);
  // };
  const addFinalText = (text) => {
    // console.log(
    //   "---allPageAttachments>>>>>>>Insie Added Text>>>>>",
    //   allPageAttachments
    // );

    const newTextAttachment = {
      id: ggID(),
      type: "text",
      x: 0,
      y: 0,
      height: 25,
      lineHeight: 1.4,
      size: fontSize,
      fontFamily: fontFamily,
      text: text,
      variableName: Object.keys(text)[0],
      color: rgbColor,
    };
    addAttachment(newTextAttachment);
    csvVarible.shift();
    setCsvVarible(csvVarible);
    setEditTextStatus(false);
    setColorCode("rgb(0.95, 0.1, 0.1)");
    setFontSize(14);
  };
  const addTestText = (varible, text, index) => {
    const newTextAttachment = {
      id: ggID(),
      type: "text",
      x: 0,
      y: 0,
      height: 25,
      lineHeight: 1.4,
      size: fontSize,
      fontFamily: fontFamily,
      text: varible,
      variableName: Object.keys(varible)[0],
      color: rgbColor,
    };
    addAttachment(newTextAttachment);
    csvVarible[index]["added"] = true;
    // csvVarible.shift();
    setCsvVarible(csvVarible);
    // setEditTextStatus(false);
    setColorCode("rgb(0.95, 0.1, 0.1)");
    setFontSize(14);
  };

  const editVaribleText = (s, pageIndex, textIndex) => {
    // console.log("===edit Test", s);
    setEditText({ data: s, pageIndex: pageIndex, textIndex: textIndex });
    setEditableTextStatus(true);
  };
  const handleChange = (color) => {
    hexToRgb(color.hex);
    setColorCode(color.hex);
    SetColor(color?.rgb);
  };

  const handleColorChange = (e) => {
    console.log("color", e.target.value);
    const rgbaColor = hexToRgba(e.target.value);
    let _data = editText.data;
    _data.color = rgbaColor;
    setEditText((prevState) => ({
      ...prevState,
      data: _data,
    }));
  };
  const hexToRgba = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const fullHexRegex =
      /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i;
    const result = shorthandRegex.exec(hex) || fullHexRegex.exec(hex);

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    const a = result[4] ? parseInt(result[4], 16) / 255 : 1;

    return { r, g, b, a };
  };

  // savePdf(allPageAttachments)

  const savePdf = async (attachments) => {
    console.log("att", attachments);
    if (!file) return;
    // setIsSaving(true);

    try {
      await save(file, attachments, varible.name, varible, "download", "");
    } catch (e) {
      // console.log("error", e);
    } finally {
      // setIsSaving(false);
    }
  };
  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };
  //   window.addEventListener("unload", async (event) => {
  //     localStorage.setItem("projectId", JSON.stringify(projectId));
  //   });
  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, [loading, projectId]);
  const testPdf = async (attachments) => {
    setMobileModalStatus(false);
    if (isScan) {
      const { data } = await API.get(
        `/project/check-test-project?projectId=${projectId}`
      );
      // console.log("=data", data);
      if (data.success) {
        if (mobileNo !== "") {
          if (!file) return;
          try {
            // console.log("=customMsg", `${customMsg}`);
            let string_result = customMsg;
            for (const key in varible) {
              if (varible.hasOwnProperty(key)) {
                const replacement =
                  typeof varible[key] === "string"
                    ? varible[key].trim()
                    : varible[key].toString();

                const regex = new RegExp(`\\$\\{${key}\\}`, "g");
                string_result = string_result.replace(regex, replacement);
              }
            }
            // console.log("-string_result", string_result);
            setLoading(true);
            const result = await save(
              file,
              attachments,
              varible.name,
              varible,
              "test",
              mobileNo,
              string_result,
              projectId
            );
            if (result.data.success) {
              setLoading(false);
              // setlogOutStatus(true);
              toast.success(result.data.message);
            } else {
              setLoading(false);
              toast.error(result.data.message);
            }
          } catch (e) {
            // console.log("error", e);
          }
        } else {
          toast.error("please enter mobile no");
        }
      } else {
        toast.error("You are already used");
      }
    } else {
      toast.error("Please scan Qr");
    }
  };
  function delay(t) {
    return new Promise((resolve) => setTimeout(resolve, t));
  }
  // console.log("delaySecond",delaySecond);
  async function sendFile() {
    //send pdf
    // for (const el of csvVariables) {
    //   setPdfLodingStatus(true);
    //   setLoginText(`${el.name} sending pdf `);
    //   // console.log("=countUser", countUser);
    //   setCountUser(countUser + 1);
    //   const string = customMsg.split(" ");
    //   let string_result = "";

    //   await string.forEach((element) => {
    //     if (element.startsWith("$")) {
    //       const s1 = element.replace(/[$,{,}]/g, "");
    //       string_result += el[`${s1}`] + " ";
    //     } else {
    //       string_result += element + " ";
    //     }
    //   });
    //   const sendPdf = await save(
    //     file,
    //     allPageAttachments,
    //     el.name,
    //     el,
    //     "send",
    //     ""
    //   );
    //   const formData = new FormData();
    //   formData.append("file", new Blob([sendPdf], { type: "application/pdf" }));
    //   formData.append("projectId", projectId);
    //   formData.append("mobileNo", el.number);
    //   formData.append("pdfName", el.name);
    //   formData.append("message", string_result);
    //   formData.append("user", JSON.stringify(el));
    //   const { data } = await API.post(
    //     `/whatsapp/send-file?projectId=${projectId}`,
    //     formData
    //   );
    //   if (!data.success) {
    //     return toast.error(data.message);
    //   } else {
    //     sendedFiles += 1;
    //     setSendedFiles(sendedFiles);
    //     await delay(delaySecond * 1000);
    //   }
    // }
    const formData = new FormData();
    // formData.append("file", new Blob([sendPdf], { type: "application/pdf" }));
    formData.append("projectId", projectId);
    // formData.append("mobileNo", el.number);
    // formData.append("pdfName", el.name);
    formData.append("message", customMsg);
    formData.append("csv", JSON.stringify(csvVariables));
    formData.append("attachment", JSON.stringify(allPageAttachments));
    const { data } = await API.post(
      `/whatsapp/sendfiles?projectId=${projectId}`,
      formData
    );
    setPdfLodingStatus(false);
    // const { data } = await API.post(`/project/sended-whatsapp-file`, {
    //   projectId: projectId,
    //   user: csvVariables[0],
    // });
    // await API.get(`/whatsapp/logout?projectId=${projectId}`);
    if (data.success) {
      toast.success(data.message);
      navigate("/functions/project", {
        state: { id: project.type },
      });
    } else toast.error("");
  }

  async function createFileWithBackend() {
    //
    const formData = new FormData();
    if (allPageAttachments && allPageAttachments.length) {
      // console.log("=pdfFilepdfFilepdfFile", pdfFile);
      formData.append("file", pdfFile);
      formData.append("message", customMsg);
      formData.append("projectId", projectId);
      formData.append("csvVariables", JSON.stringify(csvVariables));
      formData.append("attachment", JSON.stringify(allPageAttachments));
      const { data } = await API.post(`/project/create-pdf`, formData);
      // console.log("=data", data);
      if (data.success) {
        toast.success(data.message);
      }
    }
  }
  async function sendPdfFile() {
    const { data } = await API.post(
      `/whatsapp/send-pdf?projectId=${projectId}`,
      {
        projectId: projectId,
      }
    );
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    SetRgbColor({ r: r, g: g, b: b });
    return result ? `rgb(${r},${g},${b})` : null;
  }
  // const handleClose = () => setQrModalStatus(false);
  const handleMobileClose = () => setMobileModalStatus(false);

  let timer2;
  const getQr = async () => {
    if (!qrData) {
      setLoading(true);
      timer2 = setInterval(async () => {
        const sesisonData = await API.get(
          `/whatsapp/issessionsaved/?projectId=${projectId}`
        );
        if (sesisonData.data.success === false) {
          const { data } = await API.get(
            `/whatsapp/getqr/?projectId=${projectId}`
          );
          setLoading(false);
          if (data.success) {
            setQrModalStatus(true);
            setQrData(data.data);
            getQrData();
          } else {
            toast.error(data.message);
          }
        }
      }, 10000);
    }
    if (qrData) {
      clearInterval(timer2);
    }
  };
  const getQrData = () => {
    return clearInterval(timer2);
  };
  useEffect(() => {
    async function sessionExist() {
      const { data } = await API.get(
        `/whatsapp/issessionsaved?projectId=${projectId}`
      );
      if (data.success) setIsScan(true);
    }
    sessionExist();
  }, [projectId]);
  useLayoutEffect(() => setPageIndex(pageIndex), [pageIndex, setPageIndex]);
  // const AllsendFile = async () => {
  //   const data = [];
  //   for (const el of csvVariables) {
  //     let data = {};
  //     const result = await save(
  //       file,
  //       allPageAttachments,
  //       el.name,
  //       el,
  //       "send",
  //       ""
  //     );
  //     data.file = result;
  //     data.user = el;
  //     // setBufferData();
  //     data.push({ file: result, user: el });
  //   }
  // console.log("=data", data);
  // };
  const getScanQrCodeStatus = async () => {
    if (qrModalStatus) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(async () => {
        const { data } = await API.get(
          `/whatsapp/issessionsaved?projectId=${projectId}`
        );
        if (data.success) {
          setIsScan(true);
          setQrModalStatus(false);
        }
        // setSession(data);
      }, 10000);
    }
    if (!qrModalStatus) {
      clearInterval(timer);
    }
  };
  useEffect(() => {
    getScanQrCodeStatus();
    return () => clearInterval(timer);
  }, [qrModalStatus, timer]);

  async function onCancel() {
    setcancelButtonDisable(true);
    const { data } = await API.patch(
      `/project/project-status-change?projectId=${projectId}`
    );
    await API.get(`/whatsapp/logout?projectId=${projectId}`);
    if (data.success) {
      navigate("/functions/project", {
        state: { id: project.type },
      });
    }
  }

  useEffect(() => {
    const getProject = async () => {
      const res = await API.get(`/project/get/${projectId}`);
      setProject(res.data.data);
    };
    getProject();
  }, [projectId]);

  // const editableTextOnChange = (data) => {};

  return (
    <>
      <div>
        <div className="row mx-0">
          <div
            className="col-md-3"
            style={{
              borderRight: "1px solid #ddd",
              position: "relative",
            }}
            ref={containerRef}
          >
            <div className="d-flex justify-content-end">
              {!failedStatus && (
                <div className="mt-3">
                  <div className="col-md-12 ">
                    <button
                      onClick={() => savePdf(allPageAttachments)}
                      className="btn-gradient-primary p-2 border rounded"
                    >
                      Download
                    </button>
                  </div>
                </div>
              )}
              <div>
                {!isScan ? (
                  <div className="mt-3 ms-2">
                    <div
                      className="col-md-12 text-end"
                      style={{ fontSize: 16 }}
                    >
                      <button
                        onClick={() => getQr()}
                        className="btn-gradient-primary p-2 border rounded"
                      >
                        Scan QR
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 ms-2">
                    <div
                      className="col-md-12 text-end"
                      style={{ fontSize: 14 }}
                    >
                      <button
                        onClick={async () => {
                          const { data } = await API.get(
                            `/whatsapp/logout?projectId=${projectId}`
                          );
                          // console.log("--data", data);
                          if (data?.success) {
                            toast.success(data.message);
                            setIsScan(false);
                            setQrData("");
                          }
                        }}
                        className="btn-gradient-primary p-2 border rounded"
                      >
                        Log Out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {!failedStatus && (
                <>
                  <div className="mt-3">
                    <div className="col-md-12">
                      <button
                        onClick={() => setMobileModalStatus(true)}
                        className="btn-gradient-primary p-2 border rounded"
                      >
                        Test
                      </button>
                    </div>
                  </div>
                  {/* <div className="mt-3">
                    <div className="col-md-12">
                      <div
                        onClick={() => createFileWithBackend()}
                        className="btn btn-gradient-primary"
                      >
                        Create Pdf
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="col-md-12">
                      <div
                        onClick={() => sendPdfFile()}
                        className="btn btn-gradient-primary"
                      >
                        send Pdf
                      </div>
                    </div>
                  </div> */}
                </>
              )}
              <div className="mt-3">
                <div className="col-md-12 ms-2">
                  <button
                    onClick={async () => {
                      if (isScan) {
                        const { data } = await API.post("/credit/checkcredit", {
                          count: csvVariables.length,
                        });
                        // console.log("=data", data);
                        if (data.success) {
                          setCreditData(data);
                          setSendFileModalStatus(true);
                        } else {
                          toast.error(data.message);
                        }
                      } else {
                        toast.error("Please scan QR");
                      }
                    }}
                    className="btn-gradient-primary p-2 border rounded"
                  >
                    Send File
                  </button>
                </div>
              </div>
            </div>
            <div
              className="text-secondary mt-3 fw-bold"
              style={{ fontSize: 20 }}
            >
              {csvVarible.length > 0 ? "Add Values" : "Action"}
            </div>
            <div>
              {csvVarible.length > 0 && (
                <>
                  {/* <div>
                  <EditPdfVariable variable={csvVarible}/>
                </div> */}
                  <div>
                    {csvVarible.map((s, index) => {
                      return (
                        <div key={index}>
                          <div className="mb-2 rounded p-2 mt-3 border">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="w-60">{Object.values(s)}</div>
                              <div className="d-flex">
                                {!Object.values(s)[1] && (
                                  <div
                                    className="rounded p-1 ms-2 d-flex align-items-center"
                                    style={{
                                      border: "1px solid #006aa8",
                                      cursor: "pointer",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    onClick={() => {
                                      addTestText(s, Object.values(s), index);
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Plus size={20} color="#006aa8" />
                                    </div>
                                  </div>
                                )}

                                {Object.values(s)[1] && (
                                  <div
                                    className="rounded p-2 ms-2"
                                    style={{
                                      cursor: "pointer",
                                      border: "1px solid #ffbf23",
                                      width: "30px",
                                      height: "30px",
                                      backgroundColor: "#ffbf23",
                                    }}
                                    onClick={() => {
                                      allPageAttachments.map(
                                        (page, pageIndex) => {
                                          if (page.length > 0) {
                                            page.map(
                                              (textVarible, indexText) => {
                                                const textMatch = Object.keys(
                                                  textVarible.text
                                                )[0];
                                                if (
                                                  textMatch ===
                                                  Object.keys(s)[0]
                                                ) {
                                                  editVaribleText(
                                                    textVarible,
                                                    pageIndex,
                                                    indexText
                                                  );
                                                }
                                                return textVarible;
                                              }
                                            );
                                          }
                                          return page;
                                        }
                                      );
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Pen size={12} color="#006aa8" />
                                    </div>
                                  </div>
                                )}
                                {Object.values(s)[1] && (
                                  <div
                                    className="rounded p-2 ms-2"
                                    style={{
                                      cursor: "pointer",
                                      border: "1px solid #ffbf23",
                                      width: "30px",
                                      height: "30px",
                                      backgroundColor: "#ffbf23",
                                    }}
                                    onClick={() => {
                                      allPageAttachments.map(
                                        (page, pageIndex) => {
                                          if (page.length > 0) {
                                            page.map(
                                              (textVarible, indexText) => {
                                                const textMatch = Object.keys(
                                                  textVarible.text
                                                )[0];
                                                if (
                                                  textMatch ===
                                                  Object.keys(s)[0]
                                                ) {
                                                  setPageIndex(pageIndex);

                                                  removeAttachment(indexText);
                                                  csvVarible[index][
                                                    "added"
                                                  ] = false;
                                                }
                                                return textVarible;
                                              }
                                            );
                                          }
                                          return page;
                                        }
                                      );
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Trash size={14} color="#006aa8" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {csvVarible.length === 0 && (
                <>
                  {!failedStatus && (
                    <>
                      <div className="mt-3">
                        <div className="col-md-12 ">
                          <button
                            onClick={() => savePdf(allPageAttachments)}
                            className="btn-gradient-primary p-2 border rounded"
                          >
                            Download
                          </button>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="col-md-12">
                          <div
                            onClick={() => setMobileModalStatus(true)}
                            className="btn-gradient-primary p-2 border rounded"
                          >
                            Test
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="mt-3">
                    <div className="col-md-12 ">
                      <div
                        onClick={async () => {
                          if (isScan) {
                            const { data } = await API.post(
                              "/credit/checkcredit",
                              {
                                count: csvVariables.length,
                              }
                            );
                            // console.log("=data", data);
                            if (data.success) {
                              setCreditData(data);
                              setSendFileModalStatus(true);
                            } else {
                              toast.error(data.message);
                            }
                          } else {
                            toast.error("Please scan QR");
                          }
                        }}
                        className="btn-gradient-primary p-2 border rounded"
                      >
                        Send File
                      </div>
                    </div>
                  </div>
                  {/* <div onClick={() =>createFileWithBackend()} className="btn btn-primary">
                      Create Pdf
                    </div> */}
                </>
              )}
              {/* {editTextStatus && (
                <Modal
                  show={editTextStatus}
                  centered
                  onHide={() => setEditTextStatus(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                >
                  <Modal.Header className="px-4" closeButton>
                    <Modal.Title className="ms-auto page-title fw-bold">
                      Edit text
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div>
                        <div className="text-center mb-2">
                          <PreviewText
                            fontFamily={fontFamily}
                            size={fontSize}
                            color={rgbColor}
                            text={Object.values(csvVarible[0])[0]}
                          />
                        </div>
                      </div>
                      <div className="p-3 bg-light">
                        <div className="">
                          <div className="text-center page-title fw-bold">
                            Font Formating
                          </div>
                          <div className="row mt-2 ">
                            <div className="col-sm-6 col-12 ">
                              <div className="form-group">
                                <label className="lable-fw-size">
                                  FontFamily
                                </label>
                                <select
                                  name="text"
                                  className={`form-control mt-1 form-fs form-label-border p-2 ${
                                    fontFamily === "BHUJ-UNICODE"
                                      ? "font-face-bu"
                                      : fontFamily === "Surat-Unicode"
                                      ? "font-face-su "
                                      : fontFamily === "Shruti"
                                      ? "font-face-shruti"
                                      : fontFamily === "Padmaa"
                                      ? "font-face-padmaa"
                                      : fontFamily === "Mogra"
                                      ? "font-face-mogra"
                                      : fontFamily === "CourierBold"
                                      ? "font-face-courierBold"
                                      : fontFamily === "Ekatra-B"
                                      ? "font-face-ekatra-B"
                                      : ""
                                  }`}
                                  style={{ fontFamily: fontFamily }}
                                  value={fontFamily}
                                  onChange={(e) => {
                                    // console.log(
                                    //   "=e.target.value",
                                    //   e.target.value
                                    // );
                                    setFontFamily(e.target.value);
                                  }}
                                >
                                  {[
                                    "Kumar One",
                                    "Aakar",
                                    "BHUJ-UNICODE",
                                    "Ekatra",
                                    "Surat-Unicode",
                                    "Shruti",
                                    "Noto Serif Gujarati",
                                    "Noto Sans Gujarati",
                                    "Rasa",
                                    "Padmaa",
                                    "Mogra",
                                    "CourierBold",
                                    "Ekatra-B",
                                  ].map((s, i) => (
                                    <option
                                      value={s}
                                      className={
                                        s === "BHUJ-UNICODE"
                                          ? "font-face-bu"
                                          : s === "Surat-Unicode"
                                          ? "font-face-su "
                                          : s === "Shruti"
                                          ? "font-face-shruti"
                                          : s === "Padmaa"
                                          ? "font-face-padmaa"
                                          : s === "Mogra"
                                          ? "font-face-mogra"
                                          : s === "Mogra"
                                          ? "font-face-mogra"
                                          : s === "CourierBold"
                                          ? "font-face-courierBold"
                                          : s === "Ekatra-B"
                                          ? "font-face-ekatra-B"
                                          : ""
                                      }
                                      style={{ fontFamily: `${s}` }}
                                    >
                                      {s}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group mt-2">
                                <label className="lable-fw-size">
                                  Font Size
                                </label>
                                <input
                                  type="number"
                                  className="form-control mt-1 form-fs form-label-border p-2"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  defaultValue={fontSize}
                                  onChange={(e) => {
                                    let value = parseInt(e.target.value);
                                    if (e.target.max && e.target.min) {
                                      e.target.value = value; // for 000 like input cleanup to 0
                                      let max = parseInt(e.target.max);
                                      let min = parseInt(e.target.min);
                                      if (value > max)
                                        e.target.value = e.target.max;
                                      if (value < min)
                                        e.target.value = e.target.min;
                                    }
                                    setFontSize(e.target.value);
                                  }}
                                  min={1}
                                  max={30}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-12 mt-sm-0 mt-2">
                              <div className="form-group">
                                <label className="lable-fw-size">Color</label>
                                <div className="mt-1">
                                  <SketchPicker
                                    width={"90%"}
                                    color={color}
                                    disableAlpha={false}
                                    presetColors={[]}
                                    direction="vertical"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="row mt-2 mb-2">
                              <div className="col-9">
                                <div className="form-group">
                                  <label className="lable-fw-size">Color</label>
                                  <div className="mt-1">
                                    <SketchPicker
                                      width={"50%"}
                                      color={color}
                                      disableAlpha={false}
                                      presetColors={[]}
                                      // direction
                                      // presetColors ={['#ffff']}
                                      direction="vertical"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> 
                        </div>
                      </div>
                      {colorCode && fontFamily && fontSize && (
                        <div className="container mt-3">
                          <div className="text-center">
                            <div
                              onClick={() => addFinalText(csvVarible[0])}
                              className="btn btn-gradient-primary p-2"
                            >
                              Add Final Text
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Modal.Body>
                </Modal>
              )} */}
              {editableTextStatus && (
                <Modal
                  show={editableTextStatus}
                  container={containerRef.current}
                  onHide={() => setEditableTextStatus(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                  backdrop={false}
                  size="sm"
                >
                  <Modal.Header className="px-4" closeButton>
                    <Modal.Title className="ms-auto page-title fw-bold">
                      Edit text
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div className="p-3 bg-light">
                        <div className="">
                          <div className="text-center page-title fw-bold">
                            Font Formating
                          </div>
                          <div className="row mt-2 ">
                            <div className="col-12 ">
                              <div className="form-group">
                                <label className="lable-fw-size">
                                  FontFamily
                                </label>
                                <select
                                  name="text"
                                  className={`zip-select form-control mt-1 form-fs form-label-border p-2 ${
                                    editText.data.fontFamily === "BHUJ-UNICODE"
                                      ? "font-face-bu"
                                      : editText.data.fontFamily ===
                                        "Surat-Unicode"
                                      ? "font-face-su "
                                      : editText.data.fontFamily === "Shruti"
                                      ? "font-face-shruti"
                                      : editText.data.fontFamily === "Padmaa"
                                      ? "font-face-padmaa"
                                      : editText.data.fontFamily === "Rekha"
                                      ? "font-face-rekha"
                                      : editText.data.fontFamily === "Ekatra-B"
                                      ? "font-face-ekatra-B"
                                      : editText.data.fontFamily ===
                                        "HindVadodara-Bold"
                                      ? "font-face-hindVadodara-Bold"
                                      : editText.data.fontFamily ===
                                        "Lohit_Gujarati"
                                      ? "lohit_Gujarati"
                                      : editText.data.fontFamily === "Mogra"
                                      ? "mogra"
                                      : editText.data.fontFamily ===
                                        "MuktaVaani-Bold"
                                      ? "font-face-muktaVaani-Bold"
                                      : editText.data.fontFamily ===
                                        "MuktaVaani-ExtraBold"
                                      ? "font-face-muktaVaani-ExtraBold"
                                      : editText.data.fontFamily ===
                                        "RAJKOT-UNICODE"
                                      ? "font-face-RAJKOT-UNICODE"
                                      : ""
                                  }`}
                                  style={{
                                    fontFamily: editText.data.fontFamily,
                                  }}
                                  value={editText.data.fontFamily}
                                  onChange={(e) => {
                                    let _data = editText.data;
                                    _data.fontFamily = e.target.value;
                                    // console.log("=_dataEditing", _data);
                                    setEditText((prevState) => ({
                                      ...prevState,
                                      data: _data,
                                    }));
                                  }}
                                >
                                  {[
                                    "Kumar One",
                                    "Aakar",
                                    "BHUJ-UNICODE",
                                    "Ekatra",
                                    "Surat-Unicode",
                                    "Shruti",
                                    "Noto Serif Gujarati",
                                    "Noto Sans Gujarati",
                                    "Rasa",
                                    "Padmaa",
                                    "Rekha",
                                    "Ekatra-B",
                                    "HindVadodara-Bold",
                                    "Lohit_Gujarati",
                                    "Mogra",
                                    "MuktaVaani-Bold",
                                    "MuktaVaani-ExtraBold",
                                    "RAJKOT-UNICODE",
                                  ].map((s, i) => (
                                    <option
                                      value={s}
                                      className={
                                        s === "BHUJ-UNICODE"
                                          ? "font-face-bu"
                                          : s === "Surat-Unicode"
                                          ? "font-face-su "
                                          : s === "Shruti"
                                          ? "font-face-shruti"
                                          : s === "Padmaa"
                                          ? "font-face-padmaa"
                                          : s === "Rekha"
                                          ? "font-face-rekha"
                                          : s === "Ekatra-B"
                                          ? "font-face-ekatra-B"
                                          : s === "HindVadodara-Bold"
                                          ? "font-face-hindVadodara-Bold"
                                          : s === "Lohit_Gujarati"
                                          ? "font-face-lohit_Gujarati"
                                          : s === "Mogra"
                                          ? "font-face-mogra"
                                          : s === "MuktaVaani-Bold"
                                          ? "font-face-muktaVaani-Bold"
                                          : s === "MuktaVaani-ExtraBold"
                                          ? "font-face-muktaVaani-ExtraBold"
                                          : s === "RAJKOT-UNICODE"
                                          ? "font-face-RAJKOT-UNICODE"
                                          : ""
                                      }
                                      style={{ fontFamily: `${s}` }}
                                    >
                                      {s}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group mt-2">
                                <label className="lable-fw-size">Color</label>
                                <div className="mt-1">
                                  <input
                                    type="color"
                                    id="color"
                                    name="color"
                                    onChange={handleColorChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mt-2">
                              <div className="form-group">
                                <label className="lable-fw-size">
                                  Font Size
                                </label>
                                <select
                                  name="text"
                                  className={`form-control mt-1 form-fs form-label-border p-2 `}
                                  value={editText.data.size}
                                  onChange={(e) => {
                                    let _data = editText.data;
                                    _data.size = e.target.value;
                                    setEditText((prevState) => ({
                                      ...prevState,
                                      data: _data,
                                    }));
                                  }}
                                >
                                  {[
                                    2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24,
                                    26, 28, 30,
                                  ].map((s, i) => (
                                    <option value={s}>{s}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container mt-3">
                        <div className="text-center">
                          <div
                            onClick={() => {
                              // console.log("=editText", editText);
                              setPageIndex(editText.pageIndex);
                              update(editText.textIndex, editText.data);
                              setEditableTextStatus(false);
                            }}
                            className="btn-gradient-primary p-2 border rounded  p-2"
                          >
                            Edit Text
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div>
          <div className="col-md-9 col-12">
            {" "}
            <div className="container p-0 px-md-2">
              <div className="row flex-md-row flex-column align-items-center">
                <div className="col my-md-0 my-3">
                  {" "}
                  {isMultiPage && !isFirstPage && (
                    <div className="float-md-end float-start">
                      <div
                        className="btn-gradient-primary rounded-circle text-light text-center "
                        style={{
                          lineHeight: "25px",
                          height: "30px",
                          width: "30px",
                          fontWeight: "600",
                        }}
                        onClick={previousPage}
                      >
                        <ArrowLeft />
                      </div>
                    </div>
                    // <Button circular icon="angle left" onClick={previousPage} />
                  )}{" "}
                  {isMultiPage && !isLastPage && (
                    <div className="d-block d-md-none float-end">
                      <div
                        className="btn-gradient-primary rounded-circle text-light text-center "
                        style={{
                          lineHeight: "25px",
                          height: "30px",
                          width: "30px",
                          fontWeight: "600",
                        }}
                        onClick={nextPage}
                      >
                        <ArrowRight />
                      </div>
                    </div>
                  )}
                </div>
                {/* <div class="col d-block d-md-none"></div> */}

                <div className="col-12 col-md-6">
                  {currentPage && (
                    <div
                      className="edit-pdf-ui"
                      style={{ boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)" }}
                    >
                      <div
                        style={{ position: "relative" }}
                        className="overflow-auto"
                      >
                        <Page
                          dimensions={dimensions}
                          updateDimensions={setDimensionsHandler}
                          page={currentPage}
                        />
                        {/* {console.log( */}
                        {/* "-dimensions",
                          dimensions,
                          "-pageAttachments>>>>>>>>>>>>>>>>>>",
                          pageAttachments
                        )} */}
                        {dimensions && (
                          <Attachments
                            pdfName={name}
                            updateAttachment={update}
                            pageDimensions={dimensions}
                            attachments={pageAttachments}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col d-none d-md-block">
                  {" "}
                  {isMultiPage && !isLastPage && (
                    <div
                      className="btn-gradient-primary  rounded-circle text-light text-center "
                      style={{
                        lineHeight: "25px",
                        height: "30px",
                        width: "30px",
                        fontWeight: "600",
                      }}
                      onClick={nextPage}
                    >
                      <ArrowRight />
                    </div>
                    // <Button circular icon="angle right" />
                  )}
                </div>
              </div>
            </div>
          </div>
          {qrModalStatus && (
            <Modal
              show={qrModalStatus}
              backdrop="static"
              centered
              className="qrmodal"
            >
              <Modal.Body>
                <div className="m-3">
                  <div
                    style={{
                      width: "264px",
                      height: "264px",
                      background: `url(${qrData})`,
                      // backgroundSize: "cover",
                      // backgroundPosition: "center",
                    }}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )}
          {mobileModalStatus && (
            <Modal show={mobileModalStatus} onHide={handleMobileClose} centered>
              <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto page-title">
                  Add Mobile No
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="m-3">
                  <div className="form-group md-4">
                    <label className="lable-fw-size">
                      Mobile No with country code{" "}
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="tel"
                      id="mobile_no"
                      className="form-control form-fs form-label-border mt-1 "
                      name="mobile_no"
                      maxLength="12"
                      defaultValue={mobileNo}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setMobileNo(numericValue);
                      }}
                      onKeyDown={(e) => {
                        if (
                          (["e", "E", "+", "-", "."].includes(e.key) ||
                            /[a-zA-Z]/.test(e.key)) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      pattern="[0-9]*"
                    ></input>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn-gradient-yellow fw-bold p-2 border rounded"
                  onClick={handleMobileClose}
                  style={{ width: "15%" }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn-gradient-primary fw-bold p-2 border rounded "
                  style={{ width: "15%" }}
                  onClick={() => testPdf(allPageAttachments)}
                >
                  Yes
                </button>
              </Modal.Footer>
            </Modal>
          )}

          {sendFileModalStatus && (
            <Modal
              show={sendFileModalStatus}
              onHide={() => setSendFileModalStatus(false)}
              centered
            >
              <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto page-title">
                  Confirm Send File
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="m-3">
                  <div className="form-group md-4">
                    <div className="lable-fw-size">{`Your Total pdf is : ${csvVariables.length}`}</div>
                    <div className="lable-fw-size">{`Your cut balance is : ${Number(
                      creditData?.data?.totalCharge
                    ).toFixed(2)}`}</div>
                    <div className="lable-fw-size">{`Your balance will be reduced to : ${Number(
                      creditData.data.remainingCredit
                    ).toFixed(2)}`}</div>
                    <div className="lable-fw-size">{`If you want to continue, click on Continue.`}</div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn-light fw-bold p-2 btn-gradient-primary p-2 border rounded"
                  onClick={() => setSendFileModalStatus(false)}
                  style={{ width: "15%" }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-gradient-primary fw-bold p-2 border rounded "
                  style={{ width: "18%" }}
                  onClick={async () => {
                    const { data } = await API.post(`auth/update-holdBalance`, {
                      count: csvVariables.length,
                      projectId: projectId,
                    });
                    if (data.success) {
                      setSendFileModalStatus(false);
                      sendFile();
                    } else {
                      toast.error("Network Error");
                    }
                  }}
                >
                  Continue
                </button>
                {/* <Button
                  variant="primary"
                  onClick={async () => {
                    const { data } = await API.post(`auth/update-holdBalance`, {
                      count: csvVariables.length,
                      projectId: projectId,
                    });
                    if (data.success) {
                      setSendFileModalStatus(false);
                      sendFile();
                    } else {
                      toast.error("Network Error");
                    }
                  }}
                >
                  Continue
                </Button> */}
              </Modal.Footer>
            </Modal>
          )}
        </div>
        {pdfLoadingStatus && (
          <>
            <Modal
              show={pdfLoadingStatus}
              backdrop="static"
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              dialogClassName="continuebooking-modal m-3 m-sm-auto"
              centered
            >
              <Modal.Body
                className=""
                // style={{background: '#f2edf3'}}
              >
                <div className="load-data-modal-content d-flex flex-column align-items-center p-4">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: "#9a55ff" }}
                  />
                  <span className="mt-2">
                    {sendedFiles} / {csvVariables.length}
                  </span>
                  <span className="mt-2 mb-2 title-font white-8 font-20 text-uppercase">
                    PLEASE WAIT,
                  </span>
                  <span className="title-font white-8 font-20 text-uppercase">
                    {loadingText} ............
                  </span>
                  <div
                    onClick={() => onCancel()}
                    className="btn-gradient-primary mt-2 p-2 border rounded "
                    disabled={cancelButtonIsDisable}
                  >
                    Cancel
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {/* <DarkBackground disappear={pdfLoadingStatus}>
              <LoadingOverlay
                active={true}
                spinner={true}
                text={`${loadingText} ............`}
              ></LoadingOverlay>
            </DarkBackground> */}
          </>
        )}
      </div>
      {loading && (
        <div>
          <Loading loading={loading} loaderColor="#24292f" />
        </div>
      )}
      {/* <Toaster /> */}
    </>
  );
};
